import React, { useState,useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../css/Home.css";
import $ from "jquery";
import axios from 'axios';
import { useTranslation, Trans } from 'react-i18next'; 

import * as securetyAs from './securityUtill'; 




const Home = () => {
  const { t, i18n } = useTranslation();
  const locationParam = useLocation();
  const navigate = useNavigate();
  const [loginCheck, setLoginCheck] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal1, setModal1] = useState({
      onTtl : null
  ,   onCloseBool : null
  ,   onContentTxt : ""
  });
  const [userId, setUserId] = useState( null);
  const [stakingList, setStakingList] = useState([]);
  const [claimeReqList, setClaimeReqList] = useState([]);

  const [withVeckId, setWithVeckId] = useState( null);

  
  const fullTwoDataMinUnForm = (_data) => {

    const nowdate = new Date(Number( _data)); 
    //fullTwoDataMinUnForm


    let month = nowdate.getMonth() + 1;
    let day = nowdate.getDate();
    let hour = nowdate.getHours();
    let minute = nowdate.getMinutes();
    let second = nowdate.getSeconds();

    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;

    const fullYear = nowdate.getFullYear(); // 예: 2024


    return fullYear +"-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }

 
  const callUrl = ( _url) => {
    window.location.href = _url;

  }
  //http://192.168.0.80:8082
  let ipUrl = "https://vexkapi.konet.network/";

  
  const getUserInfo = async () => {
    //https://vexkapi.konet.network/api/selectUser
    let _url =  ipUrl+"api/selectUser";
    let _param = {
      tms_id : userId
    };

    axios.post(_url, _param) 
    .then(function(response) { 
      const _data = response.data;
        console.log(_data);

        setStakingList(_data.ret);
    }) 
    .catch(function(error) { 
      console.log(error);

     
    }).finally(function() { 
    // This block will always run, regardless of success or failure 

    // You can also hide loading spinners, etc., here. 
    }); 



  }

  const getClaimLst = async () => {
    //https://vexkapi.konet.network/api/selectUser
    let _url = ipUrl+"api/checktransaction";
    let _param = {
      tms_id : userId
    };

    axios.post(_url, _param) 
    .then(function(response) { 
      const _data = response.data;


      setClaimeReqList( _data.results);
      
    }) 
    .catch(function(error) { 
      console.log(error);

     
    }).finally(function() { 
    // This block will always run, regardless of success or failure 

    // You can also hide loading spinners, etc., here. 
    }); 



  }

  const initView = async () =>  {
    await getUserInfo();
    await getClaimLst();



  }
  const claim = async ( _vack_id, currentInterestRate) => {

    if(Number( currentInterestRate) <= 0){
        alert('There is no balance available to claim.');

        return;
    }



    $( ".lodingBarBack, .lodingBar").show();

    console.log(userId, _vack_id);
    let _url = ipUrl+"api/claim";
    let _param = {
      tms_id : userId
    , veck_id :_vack_id
    };

    axios.post(_url, _param) 
    .then(function(response) { 
      const _data = response.data;
      console.log(_data);
      if( _data.ret == 1){
        alert('The claim request has been successful.');
        window.location.reload();
        return;

      }
      if( _data.ret == 500404){
        alert('There is no balance available to claim.');
        return;
      }

      alert('The claim has failed.');

     
    }) 
    .catch(function(error) { 
      console.log(error);

     
    }).finally(function() { 
    // This block will always run, regardless of success or failure 
    $( ".lodingBarBack, .lodingBar").hide();
    // You can also hide loading spinners, etc., here. 
    }); 

  }

  const refund = async (_withVeckId, _amount) => {
    //refund
    if( _withVeckId == null){
      return;
    }
    setWithVeckId( _withVeckId);
    $( "#_amount_pop").html( _amount);

    $( ".withDrw, .withDrwBack").show();




  }
  
  const handleMessage = ( event) => {

  };

  const closePop = () => {

  $( ".withDrw, .withDrwBack").hide();

  }

  function checkInteger(input) {
    const regex = /^-?\d+(\.\d+)?$/;  // 정수 및 소수점 허용
    return regex.test(input);  // 입력값이 숫자인지 확인
  }

  const withCall = async () => {
    const inputs = $( "#refundInput").val();
    const maxBal = $( "#_amount_pop").text();
    const ft = checkInteger( inputs);
    if( ft != true){
      alert('Only numbers are allowed.');
      return;
    }
    if( Number(maxBal) < Number(inputs)){
      alert('The requested amount exceeds the staking balance.');
      return;
    }

    let _url = ipUrl+"api/withdraw";
    let _param = {
      tms_id : userId
    , veck_id : withVeckId
    , amount : inputs
    };
    console.log(_param);
    $( ".lodingBarBack, .lodingBar").show();

    axios.post(_url, _param) 
    .then(function(response) { 
      const _data = response.data;
    
      
      if( _data.ret == 1){
        alert('The withdrawal has been successful.');
        window.location.reload();
        return;
      }
      alert('The withdrawal has failed.');
      
      return;

     
    }) 
    .catch(function(error) { 
      console.log(error);

     
    }).finally(function() { 
    // This block will always run, regardless of success or failure 
    $( ".lodingBarBack, .lodingBar").hide();
    // You can also hide loading spinners, etc., here. 
    }); 
    
  }
  


  useEffect(() => {
     
      const queryParams = new URLSearchParams(locationParam.search);
      let user_id = queryParams.get('user_id');
    
      // window.addEventListener('message', handleMessage);
      if( user_id != "null"){
        setUserId( user_id);
      }
      
      return () => {
          console.log('컴포넌트가 언마운트될 때 실행됩니다.');
          window.removeEventListener('message', handleMessage);

      };
  }, []); 

  useEffect(() => {
  
    if(userId != null){
      initView();
      console.log( "initView!!!");
      console.log( "언제올라가는거야 대체 수정됫음2222");
      console.log( userId);
      console.log( userId);
      console.log( userId);

    }
    
    return () => {
        console.log('컴포넌트가 언마운트될 때 실행됩니다.');

    };
  }, [ userId]); 



  return (
    <div className="HomeLst bg_FFFFFF">
      <div className='withDrwBack'></div>
      <div className='withDrw'>
        <div className='withDrwTtl'>
          REFUND
        </div>
        <div className='withDrwInp'>
          <input type='number' id='refundInput' placeholder='amount' />
        </div>
        <div className='withBal' >
      <span>MAX BAL : </span>
      <span id='_amount_pop' data-bal="0"></span>
        </div>
        <div className='withDrwBtnRap'>
        <div className='withDrwBtn' onClick={(e)=>{withCall()}}>REFUND</div>
        <div className='withDrwBtnClose' onClick={(e)=>{closePop()}}>CLOSE</div>
        <div style={{ clear : 'both'}}></div>
        </div>
        
      </div>
      <div className='widthMaxStyle ltRtPadding'>
        <div className='hdrRap'>
          <div className='hdrLtLogo' onClick={(e)=>{callUrl("https://vexk.io/")}}>
            <img src={process.env.PUBLIC_URL + '/imgaes/logo.png'} />
          </div>
          {/* <div className='hdrRtRap'>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path></svg>
          </div> */}

          <div style={{clear : 'both'}}></div>
        </div>

        {/* <span className='homeTtlSub'>Total Staked</span> */}
        <div className='homeTtl'>DeFi Staking</div>
        {/* <div className='homeTtlTwo fnt_9A9A9A'>This is estimated value</div>
        <div className='homeTtlValues'>≈ $ 0.0000</div> */}



        <div className='homeTtl ttlMarginRet'><span className='homeTtlSub'>Staked</span></div>
        <div className='liRap'>
          <table border="0" width="100%" className='liTdTable'>
            <tr>
              <td className='liTdReset fnt_9A9A9A' width="200">NAME</td>
              <td className='liTdReset fnt_9A9A9A' width="80">APR</td>
              <td className='liTdReset fnt_9A9A9A'>Claimable Interest</td>
              <td className='liTdReset fnt_9A9A9A'>Staking</td>
              <td className='liTdReset fnt_9A9A9A'>Total Claimable</td>
            
            </tr>


            <tbody>
            {stakingList.map((item, index) => (
         
            <tr>
              <td className='liTdBodyReset'>
              
              {item.name == "BDP Staking VIP" ? (
                <div className="liTdBodyImg">
                  <img src={process.env.PUBLIC_URL + '/imgaes/bdp-symbol-black.png'} />
                </div>
              ) : (
                <div className="liTdBodyImg">
                  <img src={process.env.PUBLIC_URL + '/imgaes/KONPAY_LOGO_500x500-2.png'} />
                </div>
              )}
                
                {/* <span className='fnt_9A9A9A'>USDT VIP</span> */}
                <div className='liTdBodyTxt'>{item.name}</div>
                <div style={{clear :'both'}}></div>
              </td>
              <td className='liTdBodyReset'>{item.currentInterestRate}%</td>
              <td className='liTdBodyReset'>
                {item.claimReadyAmount}&nbsp;
                {item.name == "BDP Staking VIP" ? (
                    <span>BDP</span>
                  ) : (
                    <span>KON</span>
                )}
                <div className='btnStyles' onClick={(e)=>{claim(item.veck_id, item.claimReadyAmount)}}>CLAIM</div>
            
              </td>
              <td className='liTdBodyReset'>
                {item.current_amount}&nbsp;
                {item.name == "BDP Staking VIP" ? (
                    <span>BDP</span>
                  ) : (
                    <span>KON</span>
                )}
              <div className='btnStyles' onClick={(e)=>{refund( item.veck_id, item.current_amount)}}>REFUND</div>
              </td>
              <td className='liTdBodyReset'>
                {item.totalClaimedAmount}&nbsp;
                {item.name == "BDP Staking VIP" ? (
                    <span>BDP</span>
                  ) : (
                    <span>KON</span>
                )}
              </td>
              
            </tr>
            ))}

            </tbody>

          </table>
        </div>

        <div className='homeTtl ttlMarginRet'><span className='homeTtlSub'>Request List</span></div>
        <div className='liRap'>
          <table border="0" width="100%" className='liTdTable'>
            <tr>
              <td className='liTdReset fnt_9A9A9A' width="200">NAME</td>
              <td className='liTdReset fnt_9A9A9A'>Req Bal</td>
              <td className='liTdReset fnt_9A9A9A'>Req Date</td>
              <td className='liTdReset fnt_9A9A9A'>State</td>
            
            </tr>


            <tbody>
            {claimeReqList.map((item, index) => (
         
            <tr>
              <td className='liTdBodyReset'>
              
              {item.coin_type == 2 ? (
                <div>
                <div className="liTdBodyImg">
                  <img src={process.env.PUBLIC_URL + '/imgaes/bdp-symbol-black.png'} />
                </div>
                <div className='liTdBodyTxt'>BDP Staking VIP</div>
                </div>
              ) : (
                <div>
                <div className="liTdBodyImg">
                  <img src={process.env.PUBLIC_URL + '/imgaes/KONPAY_LOGO_500x500-2.png'} />
                </div>
                <div className='liTdBodyTxt'>KON VIP1</div>
                </div>
              )}


                {/* <span className='fnt_9A9A9A'>USDT VIP</span> */}
                
                <div style={{clear :'both'}}></div>
              </td>
              <td className='liTdBodyReset'>{item.amount}</td>
              <td className='liTdBodyReset'>
              {fullTwoDataMinUnForm(item.reg_date)}
            
              </td>

              

              <td className='liTdBodyReset'>
                {item.tr_state == null ? (
                 <span>Sending</span>
                ) : item.tr_state === "0x1" ? (
                 <span>Success</span>
                ) : (
                  <span>Failure</span>
                )
                
                }

              </td>
              
            </tr>
            ))}

            </tbody>

          </table>
        </div>

        <div style={{width : "100%", height : "100px"}}></div>



      </div>

      <div className='liveVer'>1.0.2</div>
          
    </div>
  );
};

export default Home;