import logo from './logo.svg';
import './App.css';
import './css/fonts.css';
import './css/bgColor.css';
import './i18n';

import { useLocation, useNavigationType ,Routes, Route } from "react-router-dom";
import Home from "./pages/Home";



import { CSSTransition, TransitionGroup } from 'react-transition-group';

function App() {

  const location = useLocation();
  const navigationType = useNavigationType();



  return (
    <div>
      <div className="lodingBarBack"></div>
      <div className="lodingBar">
        <img src={process.env.PUBLIC_URL + '/imgaes/lodingGif.gif'} />
      </div>
      <Routes location={location} basename="/">
        <Route path="/" element={<Home />} />
        
      </Routes>
      </div>
  );
}

export default App;
