import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';





i18n
    .use(initReactI18next) // pass i18n down to react-i18next
    .init({
        resources: {
            cn: {
                translation: {
                //MainPage start//
                    welcomeTtl: "欢迎来到泰德迈的创新！" //타이드마인의 혁신으로<br/>오신것을 환영합니다!
                ,   myWalletView : "查看我的钱包" //내 지갑 보기
                ,   quantityOfParticipation : "参与数量" //참여수량
                ,   compensationRound : "补偿轮次" //보상회차
                ,   walletAddress : "钱包地址" //지갑주소
                ,   share : "分享" //공유하기
                ,   mrMs : "您" //님을
                ,   invitationCall : "受邀请的泰德迈参与者" //초대하신 타이드마인 참여자
                //MainPage end//


                //UserAddressInfo start//
                , mrMs_s : "您" //님
                , youCanCreateMoreWallets : "可以创建更多的钱包" //지갑을 더 만들 수 있어요
                , walletConversionAndManagement : "钱包转换与管理" //지갑전환 및 관리
                , transactionHistory : "存取款记录"//입출금내역
                , quantityOfParticipation_2 : "参与数量" //참여수량
                , walletAddress_2 : "钱包地址" //지갑주소
                , deposit : "存款" //입금
                , withdrawal : "取款"    //출금
                //UserAddressInfo end//


                //DepositHistory start//
                , alltran : "整体" //전체
                , deposit_3 : "存款" //입금
                , withdrawal_3 : "取款"    //출금
                , purchase : "购买" //구매
                , Compensation : "补偿"//보상
                , TransactionHistory_3 : "交易记录" //거래내역
                //DepositHistory end//


                //SenderToken start
                , withdrawal_4 : "取款"
                , EnterDepositAddress : "输入存款地址" //입금 주소 입력
                , Paste : "粘贴" //붙여넣기
                , WithdrawalAmount : "取款数量" //출금수량
                , WithdrawableAmount : "可提取数量" //출금가능수량
                , WithdrawalFee : "取款手续费" //출금수수료
                //
                
                //SenderToken end

                
                //Purchases start
                , Mining : "挖矿" //마이닝
                , Finally : "终于" //드디어
                , Finally_sub : "您已到达泰德迈的 <0>创新之门</0>. <br /><0>仅可参与一个商品.</0>"
                
                , productBuyTtl : "我的挖矿产品" //나의 마이닝 상품
                , bADTtlSub : "您当前没有任何产品." //현재 보유중인 상품이 없습니다.
                , Participate : "参与"//참여하기
                //Purchases end



                //MenuLi start
                , Settings : "设置" //설정
                , Notice : "公告"
                , ChangeNickname : "更改昵称" //닉넥임 변경
                , PrivacyPolicy : "隐私政策" //개인정보처리방침
                , TermsOfService : "服务条款" //서비스 이용 약관
                , LogOut : "注销" //로그아웃
                , appVersion : "应用版本" //앱 버전
                //MenuLi end


                //NickNamePwd start
                , ChangeNickname : "更改昵称" //닉넥임 변경
                , VerifySecurityPassword : "验证安全密码" //보안 비밀번호 확인
                , secuTtlSub : "为更改昵称验证安全密码." //닉네임 변경을 위해 보안 비밀번호를 확인합니다.
                , pwdAlertTtl : "请输入6位密码." //비밀번호 6자리를 입력해주세요.
                , Next : "下一步" //다음

                //NickNamePwd end


                //NickNameRst
                , ChangeNickname_2 : "更改昵称" //닉넥임 변경
                , DuplicateCheck : "重复检查" //중복확인
                , nickInsrtInp : "请输入您的昵称." //닉넥임을 입력하세요.
                , Next_2 : "下一步" //다음
                , typeTxt_1 : "请进行昵称重复检查." //닉넥임 중복확인을 진행해 주세요.
                , typeTxt_2 : "该昵称不可用" //사용할 수 없는 닉넥임 입니다.
                , typeTxt_3 : "不允许输入表情符号或特殊字符" //이모지 또는 특수문자 입력 불가
                , typeTxt_4 : "可用" //사용 가능
                //NickNameRst End
                
                //home start
                
                , scureyTxt : "请输入您的密码." //비밀번호를 입력해주세요.
                ,  addressIns : "创建钱包" //지갑 생성
                ,  addressRet : "恢复钱包" //지갑 복구


                //home end

                //AddressInt start
                , agreeAll : "我同意全部条款" //전체 약관에 동의합니다.
                , agreePrivaty : "隐私政策（必需）" //개인정보 처리방침 (필수)
                , agreeTerms : "服务使用条款（必需）" //서비스 이용약관 (필수)
                , agreeOld : "我已满14岁.（必需）" //만 14세 이상입니다. (필수)
                , Next_4 : "下一步" //다음
                //AddressInt end


                //Recomme
                , remommPTtl : "输入推荐码" //추천인 코드 입력
                , remommPTtlInput : "推荐码" //추천인 코드
                , recommOkTxt_1 : "请输入推荐人" //추천인을 입력해주세요.
                , recommOkTxt_2 : "无效的推荐码"  //유효하지 않는 추천인 코드
                , Next_5 : "下一步" //다음
                //Recomme

                //SecurityNum
                , nmonicTtl : "种子短语" //시드 구문
                , nmonicTtlSub : "种子短语（助记词）是作为证明钱包所有权的随机单词组合生成的." //시드구문(니모닉)은 지갑의 소유권을 증명하는 무작위 단어조합으로 생성됩니다.
                , nmonicAlertTxt : "绝不要公开您的种子短语.<br />它证明了您在钱包中存储的虚拟资产的所有权." //시드구문 절대로 공개하지 마세요.<br />지갑에 보관된 가상 자산 소유를 증명합니다.
                , stbtTxt : "请按照屏幕上显示的顺序保管种子短语." //화면에 나온 순서대로 시드구문을 보관하십시오
                , copyButton : "复制" //복사하기

                , Next_6 : "下一步" //다음
                //SecurityNum




                //NickNameIns
                , nickNameRst : "设置昵称" //닉넥임 설정
                , nickNameResetInp : "请输入昵称." //닉넥임을 입력하세요.
                , checkNickName : "重复检查" //중복확인
                , NickNameOkTxt_1 : "请进行昵称重复检查." //닉넥임 중복확인을 진행해 주세요.
                , NickNameOkTxt_2 : "该昵称不可用." //사용할 수 없는 닉넥임 입니다.
                , NickNameOkTxt_3 : "无法输入表情符号或特殊字符." //이모지 또는 특수문자 입력 불가.
                , NickNameOkTxt_4 : "可用" //사용가능
                , Next_7 : "下一步" //다음
                
            
                //NickNameIns

                //SecurityPwd
                ,securetyTtl : "设置安全密码" //보안 비밀번호 설정
                ,securetyTtlSub : "为了增强安全性，请输入一个7位的安全密码。如果在使用应用时丢失密码，您可以在每次使用种子短语恢复钱包时设置新的密码." //보안을 강화하기 위하여 7자리의 안전한 비밀번호를 입력하세요. 앱 이용 시 비밀번호를 분실하는 경우 시드구문으로 지갑을 복구할때마다 새로운 비밀번호를 설정할 수 있습니다.
                ,securetyPinTtl : "不允许使用相同数字超过3次或连续数字" //동일한 숫자 3자리 초과 또는 연속된 숫자 사용불가
                ,securetyPinTtlSub  :"请再次输入" //다시 한번 입력 하세요
                ,securetyOkay : "注册" //가입
                //SecurityPwd

                //SuccessUser
                ,succTtle : "填充珍珠的波浪,<br />已到达潮汐矿!" //진주를 채우는 물결,<br />타이드마인에 도착했습니다!
                ,succBtn : "进入" //입장하기
                //SuccessUser


                //PurchasesInsert
                ,purIntTtle : "挖矿购买"
                ,purInsertMainTtlSub_1 : "购买潮汐矿商品时<br />购买数量会立即被销毁<br />从而获得参与潮汐矿网络的资格." //타인마인 상품 구매 시<br />구매 수량은 즉시 소각처리 되어<br />타이드마인의 네트워크 참여자격을 얻습니다.
                ,purInsertMainTtlSub_2 : "在总共5个商品中 每个钱包只能参与一个商品<br />且每个商品的日奖励各不相同." //총 5개의 상품 중 지갑 당 한개의 상품만<br />참여가능하며, 상품 별 일보상은 상이합니다.
                ,aCountTtl : "我的持有数量"
                ,aCountTtl_2 : "相当" //상당
                ,aCountTtl_3 : "购买所需数量" //구매 필요 수량
                ,aCountTtl_4 : "交易所标准" //거래소 기준
                ,aCountSend : "购买" //구매하기
                ,aCountSendTip : "去购买" //구매하러가기
                //PurchasesInsert

                //ReceiveDep
                , ReceiveDepTtl : "我的钱包地址" //내 지갑주소
                , ReceiveDepTpTtl: "存款" //입금
                , ReceiveDepNet: "网络" //네트워크
                , ReceiveDepCon : `
                请确认准确的地址 确保没有错误或遗漏 然后分享地址进行存款.<br />
                该钱包支持的入金币种是TIDEMINE（TID） 其他币种存入时可能会有损失风险.<br />
                在存入大金额之前 请先进行小额测试 <br />
                在存款前请确认网络. 如果使用其他网络进行存款 可能会导致存款丢失的风险.<br />
                由于错误存款造成的损失或错误存款的处理 TIDEMINE不承担任何责任 也不负责恢复.<br />`

                ,TermsOfServiceTxt : `
                第十六条（损害赔偿）
                <br />1.在法律允许的范围内，本公司不对本条款和条件中未规定的与服务有关的任何具体事项作出任何承诺或保证。此外，公司不保证非公司发行的数字资产的价值。此外，我们不保证会员在服务上发布的信息、数据或事实的可靠性或准确性，并且我们不对会员因此遭受的任何损害负责，除非会员故意或疏忽。公司。
                <br />2.因公司原因造成会员损害时，公司赔偿范围包括民法规定的一般损害，其他间接、附带或特殊情况，公司不承担责任。任何情况下的损害。
                <br/>3.尽管有第2款的规定，有下列情形之一的，会员可以承担全部或部分责任。
                <br />1.会员明知损害原因或损害发生事实却未通知公司时
                <br />2.会员故意或过失向第三方泄露钱包相关钱包及交易信息或允许会员使用钱包时
                <br/>3.此外，会员故意或过失造成损害时。
                <br />4.除公司故意或过失外，对于非公司原因造成会员损失的，公司不承担责任。
                <br/>5.若会员向本公司提供的资料与事实不符，本公司可随时停止提供服务并依据本条款全部或部分终止合同，从而给会员造成损害。因此，会员应承担损害赔偿责任。
                <br/>6.如果会员因故意或过失违反法律、本条款或公共秩序和道德而给公司造成损害，例如干扰公司系统的运行，则会员必须赔偿公司因此而遭受的所有损失。
                <br/>7.如果会员向公司索赔，公司可以通过与会员达成协议并向会员钱包支付数字资产来补偿会员的损失。
                <br/>8.公司不单独存储、管理或存储 TIDEMINE 钱包密钥。公司不负责管理会员的 TIDEMINE 钱包密钥，会员自行负责 TIDEMINE 钱包密钥的保管、管理和保管。会员在删除服务前必须备份其TIDEMINE钱包密钥，除非公司故意或过失，否则公司将不对会员未备份而删除服务而造成的任何损害承担责任。
                <br/>
                <br />第十七条（知识产权）
                <br />与公司服务相关的一切知识产权均归公司所有，会员有权在服务范围内并按照公司在本条款规定的方式使用本服务。等，并且在任何情况下均不得对本公司的知识产权进行变更、登记、变更、撤销、侵权等权利。
                <br />
                <br />第十八条（适用法律和管辖）
                <br />1.公司与会员之间提起的诉讼适用大韩民国法律。
                <br />2.公司与其会员之间发生的争议，根据韩国商事仲裁院的规则，在韩国首尔通过仲裁解决，仲裁结果对各方均有约束力。
                <br/>3.尽管有前款规定，如果会员的地址或居住地位于海外，则公司与会员之间发生的纠纷诉讼应以韩国首尔中央地方法院为管辖法院。
                <br />
                <br />补充规定
                <br />这些条款和条件自 2024 年 1 月 1 日起适用。
            `
                    ,PrivatyPolicyTxt : `
                    第一條（宗旨）<br/>
                    Dongeun Development（以下簡稱「本公司」）保護使用本公司尋求提供的服務（以下簡稱「本公司服務」）的個人（以下簡稱「使用者」或「個人」）的資訊（以下簡稱「個人資訊」）。 《個人資訊保護法》、《資訊通信網利用促進及資訊保護法》等相關法律（以下簡稱「資訊通信網法」），並及時採取措施。我們制定以下個人資訊處理政策（以下簡稱「本政策」）。
                   <br />第二條（個人資訊處理原則）
                    <br />根據個人資訊相關法律及本政策的規定，本公司可能會收集用戶的個人信息，並且在徵得個人同意的情況下，公司可能會將收集的個人信息提供給第三方。但在法律、法規等法律強制執行的情況下，本公司可以在未經個人事先同意的情況下將所收集的使用者個人資訊提供給第三方。 
                    <br />第三條（本政策的揭露）
                    <br />1. 本公司透過應用程式連接螢幕公開本政策，讓使用者隨時輕鬆查看本政策。 。
                    <br />第四條（本政策的變更）
                    <br />1.本政策可能會根據個人資訊相關法律、指南、通知或政府或公司服務的政策或內容的變更而修訂。種或多種方式進行。透過本公司營運的服務首頁上的通知框或透過單獨的視窗發佈公告的方法。以書面、傳真、電子郵件或類似方式通知使用者的方法3．本公司將在本政策修訂生效日前至少7天公告第二款的通知。但如果使用者權限發生重大變化，將至少提前30天發出通知：收集的Gya選擇資訊：去中心化錢包的暱稱。
                    <br />第五條（確認服務使用及非法使用的資訊）
                    <br />為了確認及分析使用者的服務使用及非法使用情況，本公司收集以下資訊： 1．需要收集的資訊：服務使用記錄、存取點資訊、設備資訊※ 非法使用：退出會員後重新註冊、購買產品後取消購買等。使用條款等以及身分盜竊。收集的資訊可能用於根據公司服務的使用情況進行統計和分析。
                    <br />
                    <br />
                    <br />第六條（個人資料蒐集方式）
                    <br />本公司透過以下方式收集使用者個人資訊： 1.使用者透過公司提供的網站以外的服務（例如應用程式）輸入個人資訊的方法
                    <br />第七條（個人資訊的使用）
                    <br />本公司在下列情況下使用個人資訊： 1.提供公司服務
                    <br />第八條（個人資訊的保存與使用期限）
                    <br />1.本公司在一段時間內保留和使用您的個人信息，以達到收集和使用個人資訊的目的。儘管有前款規定，根據公司內部政策，非法服務使用記錄自退出會員之日起最多保存一年，以防止欺詐性註冊和使用。
                    <br />第九條（個人資料依法保存、使用期限）
                    <br />本公司依據相關法律法規的規定，保留及使用個人資訊如下： 1．根據電子商務消費者保護法等規定保留的資訊和保留期限。合約或合約解除等記錄：5年 B.付款、供貨等記錄：5年。消費者投訴或爭議解決紀錄：3年。有關展示廣告的記錄：6個月2。依通訊秘密保護法規定之資料保留及保留期間網站日誌記錄資料：3個月3．根據《電子金融交易法》保留的資訊和保留期限電子金融交易記錄：5年4。位置資訊保護與使用法 A.個人位置資訊記錄：6個月
                    <br />第十條（個人資訊銷毀原則）
                    <br />原則上，當不再需要個人資訊時，例如當處理使用者個人資訊的目的已達到或超過保留/使用期限時，公司將立即銷毀相關資訊。
                    `


                 , ReceiveDepBtTtl : "存款注意事项" //입금유의사항
                 , myRetCode : "我的推荐码" //내 추천인 코드
                 , myRetCodeCnt : "商品购买后会生效. 推荐码将会生成." //상품 구매 후 동작합니다. 추천인 코드가 생성됩니다.
                 , share1 : "钱包地址" //지갑주소
                 , share2 : "推荐码" //추천인 코드
                 , txt1 : "文本已复制到剪贴板！" //텍스트가 클립보드에 복사되었습니다!
                 , txt2 : "请购买商品！" //상품을 구매해주세요!
                 , txt3 : "请同意使用条款." //이용약관에 동의해주세요.
                 , txt4 : "系统错误。没有数据。" //시스템 오류입니다. 데이터가 없습니다.
                 , txt5 : "确认安全密码" //보안 비밀번호 확인
                 , txt6 : "由于安全措施，请输入您现有的钱包密码" //보안적용으로 인해 기존 지갑 비밀번호를 입력해 주세요.
                 , txt7 : "请填写助记词." //시드구문을 넣어주세요.
                 , txt8 : "恢复与联动" //복구 및 연동
                 , txt9 : "助记词与原始不符。请重新输入。" //시드구문이 원본과 다릅니다. 다시 진열해주세요.
                 , txt10 : "请您输入推荐人代码"//추천인 코드를 입력해주세요
                 , txt11 : "无效的推荐人代码。请检查代码。" //유효하지 않는 추천인 코드입니다. 코드를 확인해주세요.
                 , txt12 : "请设置您的昵称。" //닉넥임 설정을 진행해주세요.
                 , txt13 : "请确认您的原安全密码" //기존 보안 비밀번호를 확인해주세요
                 , txt14 : "钱包连接完成" //지갑연동 완료
                 , txt15 : "生成与连接" //생성 및 연동
                 , txt16 : "提款" //출금하기
                 , txt17 : "可提取数量" //출금 가능수량
                 , txt18 : "总提款数量" //총 출금 수량
                 , txt19 : "种子短语" //시드 구문
                 , txt20 : "密码不一致" //비밀번호가 같지 않습니다.
                 , txt21 : "请选择商品" //상품을 선택해주세요.
                 , txt22 : "完成" //완료
                 , txt23 : "在删除之前，建议您保存助记词。您确定要删除吗？" //삭제하시기전, 니모닉 구문을 보관하시는것을 추천드립니다. 정말로 삭제하시겠습니까?
                 , txt24 : "删除成功" //삭제가 성공하였습니다.
                 
                        
                 //닉넥임 설정을 진행해주세요.
                
                //ReceiveDep
                

                //UserAddressList
                ,  UserArTxt1 : "购买商品" //구매상품
                ,  UserArTxt2 : "没有购买记录" //
                ,  UserArTxt3 : "钱包转换与管理" //지갑전환 및 관리 
                ,  UserArTxt4 : "持有数量" //보유수량
                ,  UserArTxt5 : "当前选择的钱包" //현재 선택 지갑
                ,  UserArTxt6 : "选择" //선택하기
                ,  UserArTxt7 : "转换钱包" //지갑전환하기
                ,  UserArTxt8 : "删除钱包转换" //지갑전환삭제
                ,  UserArTxt9 : "复制入金地址" //입금주소복사
                ,  UserArTxt10 : "生成新钱包"//신규지갑생성
                ,  UserArTxt11 : "导入钱包"//지갑불러오기
                
                //
                //UserAddressList

                //bottomTxt
                ,   btTxt1 : "主页" //홈
                ,   btTxt2 : "挖矿" //마이닝
                ,   btTxt3 : "钱包" //월렛
                ,   btTxt4 : "设置" //설정
                ,   btTxt5 : "管理员" //설정
                //bottomTxt



                }
            },
            kr: {
                translation: {
                    //MainPage start//
                    welcomeTtl: "타이드마인의 혁신으로<br/>오신것을 환영합니다!"
                    , myWalletView : "내 지갑 보기"
                    , quantityOfParticipation : "참여수량"
                    , compensationRound : "보상회차"
                    , walletAddress : "지갑주소"
                    , share : "공유하기" //공유하기
                    , mrMs : "님을"
                    , invitationCall : "초대하신 타이드마인 참여자"
                    
                    
                    //MainPage end//

                    //UserAddressInfo start//
                    , mrMs_s : "님"
                    , youCanCreateMoreWallets : "지갑을 더 만들 수 있어요"
                    , walletConversionAndManagement : "지갑전환 및 관리"
                    , transactionHistory : "입출금내역" 
                    , quantityOfParticipation_2 : "참여수량"
                    , walletAddress_2 : "지갑주소"
                    , deposit : "입금"
                    , withdrawal : "출금"
                    //UserAddressInfo end//

                     //DepositHistory start//
                    , alltran : "전체"
                    , deposit_3 : "입금"
                    , withdrawal_3 : "출금" 
                    , purchase : "구매"
                    , Compensation : "보상"
                    , TransactionHistory_3 : "거래내역" //거래내역
                    //DepositHistory end//


                    //SenderToken start
                    , withdrawal_4 : "출금"
                    , EnterDepositAddress : "입금 주소 입력" //
                    , Paste : "붙여넣기"
                    , WithdrawalAmount : "출금수량"
                    , WithdrawableAmount : "출금가능수량"
                    , WithdrawalFee : "출금수수료" //출금수수료
                    //SenderToken end

                    //Purchases start
                    , Mining : "마이닝" 
                    , Finally : "드디어" //드디어
                    , Finally_sub : "타이드마인 <0>혁신의 문</0>앞에 도착했습니다.<br /> <0>오직,단 한개의 상품만 참여가능합니다.<0>"

                    
                    , productBuyTtl : "나의 마이닝 상품" //
                    , bADTtlSub : "현재 보유중인 상품이 없습니다." 
                    , Participate : "참여하기"
                    //Purchases end

                    //MenuLi start
                    , Settings : "설정" //설정
                    , Notice : "공지사항"
                    , ChangeNickname : "닉넥임 변경"
                    , PrivacyPolicy : "개인정보처리방침"
                    , TermsOfService : "서비스 이용 약관"
                    , LogOut : "로그아웃" //로그아웃
                    , appVersion : "앱 버전"
                    //MenuLi end
                    
                    //NickNamePwd start
                    , ChangeNickname : "닉넥임 변경" //닉넥임 변경
                    , VerifySecurityPassword : "보안 비밀번호 확인"
                    , secuTtlSub : "닉네임 변경을 위해 보안 비밀번호를 확인합니다." //
                    , pwdAlertTtl : "비밀번호 6자리를 입력해주세요."
                    , Next : "다음" //다음
                    //NickNamePwd end


                    //NickNameRst
                    , ChangeNickname_2 : "닉넥임 변경" //닉넥임 변경
                    , DuplicateCheck : "중복확인" //중복확인
                    , nickInsrtInp : "닉넥임을 입력하세요." //닉넥임을 입력하세요.
                    , Next_2 : "다음" //다음
                    , typeTxt_1 : "닉넥임 중복확인을 진행해 주세요." //닉넥임 중복확인을 진행해 주세요.
                    , typeTxt_2 : "사용할 수 없는 닉넥임 입니다." //사용할 수 없는 닉넥임 입니다.
                    , typeTxt_3 : "이모지 또는 특수문자 입력 불가" //이모지 또는 특수문자 입력 불가
                    , typeTxt_4 : "사용 가능" //사용 가능
                    //NickNameRst End


                    //home start
                    , scureyTxt : "비밀번호를 입력해주세요."
                    ,  addressIns : "지갑 생성"
                    ,  addressRet : "지갑 복구"
                    
                    //home end

                    //AddressInt start
                    , agreeAll : "전체 약관에 동의합니다."
                    , agreePrivaty : "개인정보 처리방침 (필수)"
                    , agreeTerms : "서비스 이용약관 (필수)"
                    , agreeOld : "만 14세 이상입니다. (필수)"
                    , Next_4 : "다음" //다음
                    
                    //AddressInt end

                    //Recomme
                    , remommPTtl : "추천인 코드 입력" //추천인 코드 입력
                    , remommPTtlInput : "추천인 코드"
                    , recommOkTxt_1 : "추천인을 입력해주세요." //추천인을 입력해주세요.
                    , recommOkTxt_2 : "유효하지 않는 추천인 코드"  //유효하지 않는 추천인 코드
                    , Next_5 : "다음" //다음
                    //Recomme

                    //SecurityNum
                    , nmonicTtl : "시드 구문"
                    , nmonicTtlSub : "시드구문(니모닉)은 지갑의 소유권을 증명하는 무작위 단어조합으로 생성됩니다." //시드구문(니모닉)은 지갑의 소유권을 증명하는 무작위 단어조합으로 생성됩니다.
                    , nmonicAlertTxt : "시드구문 절대로 공개하지 마세요.<br />지갑에 보관된 가상 자산 소유를 증명합니다." //시드구문 절대로 공개하지 마세요.<br />지갑에 보관된 가상 자산 소유를 증명합니다.
                    , stbtTxt : "화면에 나온 순서대로 시드구문을 보관하십시오."
                    , copyButton : "복사하기" //복사하기
                    , Next_6 : "다음" //다음
                    //SecurityNum

                    //NickNameIns
                    , nickNameRst : "닉넥임 설정" //닉넥임 설정
                    , nickNameResetInp : "닉넥임을 입력하세요." //닉넥임을 입력하세요.
                    , checkNickName : "중복확인" //중복확인
                    , NickNameOkTxt_1 : "닉넥임 중복확인을 진행해 주세요." //닉넥임 중복확인을 진행해 주세요.
                    , NickNameOkTxt_2 : "사용할 수 없는 닉넥임 입니다." //사용할 수 없는 닉넥임 입니다.
                    , NickNameOkTxt_3 : "이모지 또는 특수문자 입력 불가." //이모지 또는 특수문자 입력 불가.
                    , NickNameOkTxt_4 : "사용가능" //사용가능
                    , Next_7 : "다음" //다음
                
                    //NickNameIns
                    

                    //SecurityPwd
                    ,securetyTtl : "보안 비밀번호 설정" //보안 비밀번호 설정
                    ,securetyTtlSub : "보안을 강화하기 위하여 7자리의 안전한 비밀번호를 입력하세요. 앱 이용 시 비밀번호를 분실하는 경우 시드구문으로 지갑을 복구할때마다 새로운 비밀번호를 설정할 수 있습니다." //보안을 강화하기 위하여 7자리의 안전한 비밀번호를 입력하세요. 앱 이용 시 비밀번호를 분실하는 경우 시드구문으로 지갑을 복구할때마다 새로운 비밀번호를 설정할 수 있습니다.
                    ,securetyPinTtl : "동일한 숫자 3자리 초과 또는 연속된 숫자 사용불가" //동일한 숫자 3자리 초과 또는 연속된 숫자 사용불가
                    ,securetyPinTtlSub  :"다시 한번 입력 하세요" //다시 한번 입력 하세요
                    ,securetyOkay : "가입" //가입
                    //SecurityPwd

                    //SuccessUser
                    ,succTtle : "진주를 채우는 물결,<br />타이드마인에 도착했습니다!" //진주를 채우는 물결,<br />타이드마인에 도착했습니다!
                    ,succBtn : "입장하기" //입장하기
                    //SuccessUser

                    //PurchasesInsert
                    ,purIntTtle : "마이닝 구매"
                    ,purInsertMainTtlSub_1 : "타인마인 상품 구매 시<br />구매 수량은 즉시 소각처리 되어<br />타이드마인의 네트워크 참여자격을 얻습니다."
                    ,purInsertMainTtlSub_2 : "총 5개의 상품 중 지갑 당 한개의 상품만<br />참여가능하며, 상품 별 일보상은 상이합니다." //총 5개의 상품 중 지갑 당 한개의 상품만<br />참여가능하며, 상품 별 일보상은 상이합니다.
                    ,aCountTtl : "나의 보유 수량"
                    ,aCountTtl_2 : "상당" //상당
                    ,aCountTtl_3 : "구매 필요 수량" //구매 필요 수량
                    ,aCountTtl_4 : "거래소 기준" //거래소 기준
                    ,aCountSend : "구매하기" //구매하기
                    ,aCountSendTip : "구매하러가기" //구매하러가기
                    //PurchasesInsert

                    //PrivatyPolicy
                    ,PrivatyPolicyTxt : `
                        제1조(목적)< br/>
                        동은개발(이하 ‘회사'라고 함)는 회사가 제공하고자 하는 서비스(이하 ‘회사 서비스'）를 이용하는 개인(이 하 ‘이용자’ 또는 ‘개인'）의 정보(이하 ‘개인정보'）를 보호하기 위해, 개인정보보호법, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법'） 등 관련 법령을 준수하고 , 서비스 이용자의 개인 정보 보호 관련한 고충을 신속하고 원활하게 처리 할 수 있도록 하기 위하여 다음과 같이 개인정보처리 방침(이하 ‘본 방침'） 을 수립합니다.
                        <br />제2조(개인정보 처리의 원칙)
                        <br />개인정보 관련 법령 및 본 방침에 따라 회사는 이용자의 개인정보를 수집할 수 있으며 수집된 개인정보 는 개인의 동의가 있는 경우에 한해 제3자에게 제공될 수 있습니다. 단, 법령의 규정 등에 의해 적법하 게 강제되는 경우 회사는 수집한 이용자의 개인정보를 사전에 개인의 동의 없이 제3자에게 제공할 수도 있습니다.
                        <br />제3조(본 방침의 공개)
                        <br />1.회사는 이용자가 언제든지 쉽게 본 방침을 확인할 수 있도록 애플리케이션 연결화면을 통해 본 방침을 공개하고 있습니다.2.회사는 제1항에 따라 본 방침을 공개 하는 경우 글자 크기, 색상 등을 활용하여 이용자가 본 방침을 쉽게 확인 할 수 있도록 합니다.
                        <br />제4조(본 방침의 변경)
                        <br />1.본 방침은 개인정보 관련 법령, 지침, 고시 또는 정부나 회사 서비스의 정책이나 내용의변경에 따라 개정 될 수 있습니다.2.회사는 제1항에 따라 본 방침을 개정하는 경우 다음 각 호 하나 이상의 방법으로 공지합니다.가. 회사가 운영하는 서비스의 첫 화면의 공지사항란 또는 별도의 창을 통하여 공지하는 방법 나. 서면·모사전송·전자우편 또는 이와 비슷한 방법으로 이용자에게 공지하는방법3. 회사는 제2항의 공지는 본 방침 개정의 시행일로부터 최소 7일 이전에 공지합니다. 다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 공지합니다.갸 선택 수집 정보: 탈중앙화 지갑의 별칭
                        <br />제5조(서비스 이용 및 부정 이용 확인을 위한 정보)
                        <br />회사는 이용자의 서비스 이용 및 부정 이용의 확인 및 분석을 위하여 다음과 같은 정보를 수집합니다.1. 필수 수집 정보: 서비스 이용기록, 접속지 정보 및 기기정보※ 부정이용 : 회원탈퇴 후 재가입, 상품구매 후 구매취소 등을 반복적으로 행하는 등 회사가 제공하는 할인쿠폰, 이벤트 혜택 등의 경제상 이익을 불·편법적으로 수취하는 행위, 이용약관 등에서 금지하고 있는 행위, 명의도용 등의 불·편법행위 등을 말합니다. 수집되는 정보는 회사 서비스 이용에 따른 통계∙ 분석에 이용될 수 있습니다.
                        <br />
                        <br />
                        <br />제6조(개인정보 수집 방법)
                        <br />회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다.1. 어플리케이션 등 회사가 제공하는 홈페이지 외의 서비스를 통해 이용자가 자신의 개인정보를 입력하는 방식
                        <br />제7조(개인정보의 이용)
                        <br />회사는 개인정보를 다음 각 호의 경우에 이용합니다.1. 회사의 서비스를 제공하기 위한 경우
                        <br />제8조(개인정보의 보유 및 이용기간)
                        <br />1. 희사는 이용자의 개인정보에 대해 개인정보의 수집·이용 목적이 달성을 위한 기간동안 개인정보를 보유 및 이용합니다.2. 전항에도 불구하고 회사는 내부 방침에 의해 서비스 부정이용기록은 부정 가입 및 이용 방지를 위하여 회 원 탈퇴 시점으로부터 최대 1년간 보관합니다.
                        <br />제9조(법령에 따른 개인정보의 보유 및 이용기간)
                        <br />회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다.1. 전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유정보 및 보유기간 가. 계약 또는 정약철회 등에 관한 기록 : 5년나. 대금결제 및 재화 등의 공급에 관한 기록 : 5년 다. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 라. 표시 광고에 관한 기록 : 6개월2. 통신비밀보호법에 따른 보유정보 및 보유기간 가. 웹사이트 로그 기록 자료 : 3개월3. 전자금융거래법에 따른 보유정보 및 보유기간 가. 전자금융거래에 관한 기록 : 5년4. 위치정보의보호 및 이용 등에 관한법률 가. 개인위치정보에 관한 기록 : 6개월
                        <br />제10조(개인정보의 파기원칙)
                        <br />희사는 원칙적으로 이용자의 개인정보 처리 목적의 달성, 보유·이용기간의 경과 등 개인정보가 필요하 지 않을 경우에는 해당 정보를 지체 없이 파기합니다.
                    `
                    //PrivatyPolicy
                    ,TermsOfServiceTxt : `
                        제 16조 (손해배상)
                        <br />1. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대하여 약정이나 보증을 하지 않습니다. 또한, 회사는 회사가 발행하지 아니한 디지털 자산의 가치를 보증하지 않습니다. 또한 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여 회사의 고의나 과실이 있지 않는 한 책임을 지지 않습니다.
                        <br />2. 회사의 책임 있는 사유로 인하여 회원에게 손해가 발생한 경우 회사의 손해배상 범위는 민법에서 정하고 있는 통상손해를 포함하고 기타 간접, 부수, 특별 손해에 대해서는 어떠한 경우에도 책임을 지지 않습니다.
                        <br />3. 제2항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 회원이 그 책임의 전부 또는 일부를 부담할 수 있습니다.
                        <br />1. 회원이 손해 발생의 원인 또는 손해 발생 사실을 알았음에도 불구하고 회사에 통지하지 않은 경우
                        <br />2. 회원이 고의 또는 과실로 제3자에게 지갑 관련 지갑 및 거래 정보를 유출하거나 지갑을 사용하게 한 경우
                        <br />3. 그 외에 손해 발생에 있어서 회원의 고의나 과실이 있는 경우
                        <br />4. 회사의 고의나 과실이 있지 않는 한 회사는 회사의 책임 없는 사유로 인하여 회원에게 발생한 손해에 대하여는 배상책임이 없습니다.
                        <br />5. 회원이 회사에게 제공한 정보가 사실과 다를 경우, 회사는 언제든지 서비스의 제공을 중단하고 본 약관에 의해 계약 전체 또는 일부를 해지할 수 있으며, 이로 인하여 회사에게 손해가 발생한 경우, 회원에게 그 손해의 배상을 청구할 수 있습니다.
                        <br />6. 회원이 회사의 시스템 운영을 방해하는 등 고의 또는 과실로 법령, 본 약관, 공서양속에 위배하는 행위 등을 통하여 회사에 손해를 발생시킨 경우에는 회사에 발생한 손해를 모두 배상해야 합니다.
                        <br />7. 회원이 회사에게 손해배상을 청구할 경우 회사는 회원과 합의하여 회원의 지갑으로 디지털 자산을 지급하는 방법으로 회원의 손해를 배상할 수 있습니다.
                        <br />8. 회사는 TIDEMINE Wallet Key을 별도 보관, 관리 또는 저장하지 않습니다. 회사는 회원의 TIDEMINE Wallet Key 관리에 대한 책임이 없으며 TIDEMINE Wallet Key의 보관, 관리 및 저장에 관한 책임은 전적으로 회원에게 있습니다. 회원은 서비스 삭제 전 반드시 TIDEMINE Wallet Key를 백업해야 하며 회원이 백업하지 않고 서비스를 삭제하여 발생하는 모든 손해에 대해서는 회사의 고의 또는 과실이 없는 한 배상책임이 없습니다.
                        <br />
                        <br />제 17조 (지식재산권)
                        <br />회사의 서비스 관련 모든 지식재산권은 회사의 소유이며 회원은 서비스 범위 내에서 본 약관 등으로 회사가 정한 방법에 따라 서비스를 이용할 권리를 가지며 어떠한 경우에도 회사의 지식재산권의 변경, 등록, 취소, 침해 등을 할 권리가 없습니다.
                        <br />
                        <br />제 18조 (준거법 및 재판관할)
                        <br />1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
                        <br />2. 회사와 회원간 발생한 분쟁은 대한상사중재원의 규율로 대한민국 서울에서 중재로 해결하고 중재 결과는 모든 당사자를 구속합니다.
                        <br />3. 해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.
                        <br />
                        <br />부칙
                        <br />이 약관은 2024년 01월 01일부터 적용됩니다.
                    `



                     //ReceiveDep
                        , ReceiveDepTtl : "내 지갑주소" //내 지갑주소
                        , ReceiveDepTpTtl: "입금" //입금
                        , ReceiveDepNet: "네트워크" //네트워크
                        , ReceiveDepCon : `
                            정확한 주소를 확인하여 오기 또는 주소의 누락이 없도록 주소를 공유하여 입금을 진행하세요.
                            <br />해당 지갑의 입금가능한 코인은 TIDEMINE(TID) 입니다. 타 코인 입금 시 분실의 위험이 있습니다.
                            <br />큰 금액을 입금하기 전 소액으로 테스트를 진행하세요.
                            <br />입금 전 네트워크를 확인하세요. 타 네트워크로 입금 시 입금이 누락되어 분실의 위험이 있습니다.
                            <br />오입금으로 인한 피해 또는 오입금 반영처리는 타이드마인에서 책임지지 않으며, 복구에 대한 책임이 없습니다.`
                        , ReceiveDepBtTtl : "입금유의사항"
                        , myRetCode : "내 추천인 코드"
                        , myRetCodeCnt : "상품 구매 후 동작합니다. 추천인 코드가 생성됩니다." //상품 구매 후 동작합니다. 추천인 코드가 생성됩니다.
                        , share1 : "지갑주소"
                        , share2 : "추천인 코드" //추천인 코드
                        , txt1 : "텍스트가 클립보드에 복사되었습니다!" //텍스트가 클립보드에 복사되었습니다!
                        , txt2 : "상품을 구매해주세요!" //텍스트가 클립보드에 복사되었습니다!
                        , txt3 : "이용약관에 동의해주세요." //이용약관에 동의해주세요.
                        , txt4 : "시스템 오류입니다. 데이터가 없습니다." //시스템 오류입니다. 데이터가 없습니다.
                        , txt5 : "보안 비밀번호 확인" //보안 비밀번호 확인
                        , txt6 : "보안적용으로 인해 기존 지갑 비밀번호를 입력해 주세요." //보안적용으로 인해 기존 지갑 비밀번호를 입력해 주세요.
                        , txt7 : "시드구문을 넣어주세요." //시드구문을 넣어주세요.
                        , txt8 : "복구 및 연동" //복구 및 연동
                        , txt9 : "시드구문이 원본과 다릅니다. 다시 진열해주세요." //시드구문이 원본과 다릅니다. 다시 진열해주세요.
                        , txt10 : "추천인 코드를 입력해주세요"//추천인 코드를 입력해주세요
                        , txt11 : "유효하지 않는 추천인 코드입니다. 코드를 확인해주세요." //유효하지 않는 추천인 코드입니다. 코드를 확인해주세요.
                        , txt12 : "닉넥임 설정을 진행해주세요." //닉넥임 설정을 진행해주세요.
                        , txt13 : "기존 보안 비밀번호를 확인해주세요" //기존 보안 비밀번호를 확인해주세요
                        , txt14 : "지갑연동 완료" //지갑연동 완료
                        , txt15 : "생성 및 연동" //생성 및 연동
                        , txt16 : "출금하기" //출금하기
                        , txt17 : "출금 가능수량" //출금 가능수량
                        , txt18 : "총 출금 수량" //총 출금 수량
                        , txt19 : "시드 구문" //시드 구문
                        , txt20 : "비밀번호가 같지 않습니다" //비밀번호가 같지 않습니다.
                        , txt21 : "상품을 선택해주세요." //상품을 선택해주세요.
                        , txt22 : "완료" //완료
                        , txt23 : "삭제하시기전, 니모닉 구문을 보관하시는것을 추천드립니다. 정말로 삭제하시겠습니까?" //삭제하시기전, 니모닉 구문을 보관하시는것을 추천드립니다. 정말로 삭제하시겠습니까?
                        , txt24 : "삭제가 성공하였습니다."
                        //

                        //
                 
                    //ReceiveDep
                    
                    //UserAddressList
                    ,  UserArTxt1 : "구매상품" //
                    ,  UserArTxt2 : "구매이력없음" //
                    ,  UserArTxt3 : "지갑전환 및 관리" //지갑전환 및 관리 
                    ,  UserArTxt4 : "보유수량" //보유수량
                    ,  UserArTxt5 : "현재 선택 지갑" //
                    ,  UserArTxt6 : "선택하기" //선택하기
                    ,  UserArTxt7 : "지갑전환하기" //선택하기
                    ,  UserArTxt8 : "지갑전환삭제" //지갑전환삭제
                    ,  UserArTxt9 : "입금주소복사" //입금주소복사
                    ,  UserArTxt10 : "신규지갑생성"//신규지갑생성
                    ,  UserArTxt11 : "지갑불러오기"//지갑불러오기
        


                    //        
                    //UserAddressList
                    

                    //bottomTxt
                    ,   btTxt1 : "홈" //홈
                    ,   btTxt2 : "마이닝" //마이닝
                    ,   btTxt3 : "월렛" //월렛
                    ,   btTxt4 : "설정" //설정
                    ,   btTxt5 : "어드민" //설정
                    //bottomTxt


                }
            }
        },
        lng: process.env.REACT_APP_API_LANG, // 기본 언어 설정
        fallbackLng: process.env.REACT_APP_API_LANG, // fallback 언어 설정
        interpolation: {
            escapeValue: false // react는 기본적으로 XSS 방지 기능이 있으므로 false로 설정
        }
    });

export default i18n;
